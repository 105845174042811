import React from 'react'
import styles from '../styles/biostyle.module.css'
import Headshot from "../images/headshot.jpeg"

const Bio = () => {
  return (

        <div className={styles.user}>

        <img src="https://images.ctfassets.net/xx3cswb5bd9m/1gK70TMP706zjN0yo8zd3z/9ee4e47d32327f9d214510aa9d0e61c8/DSC04413.jpg"
 className={styles.avatar}></img>
        <div className={styles.description}>
          <p className={styles.excerpt}>
          I am an internationally experienced
          UX designer who has developed e-commerce
          platforms and promotional website designs
          for companies in Singapore, London, Syracuse,
          and New York. In 2019, I was hired by ClassLink to
          revamp their promotional website and in 2020, I took
          over direction of their UX design team. <br /><br />
          
          Previously, I co-headed UX development for Lazada EXPRESS, a 
          same-day delivery e-commerce platform for the Lazada
          Group, a company under Alibaba. I also designed
          and launched the website for Terradiol, a
          biopharmaceutical company promoting
          cannabinoid healthcare.<br /><br />

          Beyond UX strategy, design, and implementation
          I possesses a variety of skills, including
          physical prototyping, soft goods production, 3D
          modeling, simulation, and motion graphics. In college,
          a passion for medical design lead to a study about
          hospital gowns and their affect on patient dignity.
          A high proficiency with HTML, CSS, and a general
          knowledge of IT-related skills makes me a great
          communicator when it comes to understanding technical
          project specs and details.<br /><br />

          Personally, I am passionate about all things hacking
          and maker-related, and am a born cinephile.
          </p>
        </div>
      </div>

  )
}

export default Bio
