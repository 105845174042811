import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react'
import Bio from "../components/Bio.js"
import Resume from "../components/Resume.js"

const About = () => {
  const postNode = {
    title: `About - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{`About - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="about" customTitle />

      <Container>
        <PageTitle>About</PageTitle>

        <Bio />

        <p className="certheader"><h2>Certifications:</h2></p>
        <p>SolidWorks Professional - <a href="https://cv.virtualtester.com/qr/?b=SLDWRKS&i=C-5F9R3PN82R">C-5F9R3PN82R</a> | 2019</p>
        <p>SolidWorks Associate - <a href="https://cv.virtualtester.com/qr/?b=SLDWRKS&i=C-BNWYVBN5SS">C-BNWYVBN5SS</a> | 2018</p>

        <br /><br /><p><a target="_blank" href="/Erik-Reiblein-2022-Resume.pdf">PDF resume available by clicking here</a></p>

        <Resume />

      </Container>
    </Layout>
  )
}

export default About
