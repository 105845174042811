import React from 'react'
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react'

const Resume = () => {
  return (

    <Timeline lineColor={'#ddd'}>
      <TimelineItem
        key="001"
        dateText="01/2020 – Present"
        style={{ color: '#e86971' }}
      >
        <h3>UX Team Lead, ClassLink</h3>
        <h4>Clifton, NJ </h4>
        <p>I am currently responsible for producing prototypes and 
        coordinating design between ClassLink's suite of apps. I also help maintain 
        CSS and animation across the platform. Previously, I handeled the redesign, relaunch,
        and branding for <a href="https://classlink.com">ClassLink.com</a>.
        </p>
      </TimelineItem>
      <TimelineItem
        key="002"
        dateText="05/2018 – Present"
      >
        <h3>Freelance Designer, Reiblein Design Consultancy</h3>
        <h4>White Plains, NY</h4>
        <p>
        Developed projects for individuals and design companies
        across a variety of fields, including UX, web, and app design,
        as well as brand positioning and product rendering.
        </p>
      </TimelineItem>
      <TimelineItem
        key="003"
        dateText="06/2017 – 04/2018"
      >
        <h3>UX Designer, Lazada Express</h3>
        <h4>White Plains, NY + Singapore</h4>
        <p>
        I helped prototype and build the interfaces for the Lazada
        Group’s new same-day delivery service, EXPRESS, including
        separate <a href="/lazada-express-ecommerce-app/">app designs</a> for the drivers, in-store shoppers,
        and end-users. I also assisted with the <a href="/lazada-express-branding/">brand positioning
        and graphic design</a> for EXPRESS promotion. Lazada is a part
        of the Alibaba Group.
        </p>
      </TimelineItem>
      <TimelineItem
        key="004"
        dateText="06/2017 – 08/2017"
      >
        <h3>Web Design Instructor + Technology Curriculum Director, CodeREV Kids</h3>
        <h4>Nantucket, MA</h4>
        <p>
        <a href="https://www.coderevkids.com/">CodeREV Kids</a> is a chain of Summer camps based in
        California with a focus on educating young people
        through code and technology. I helped handle their
        2017 Nantucket expansion by organizing and teaching
        a curriculum which incorporated manufacturing principles,
        Java programming, 3D printing, CAD modeling software,
        web design, and many other advanced concepts.
        </p>
      </TimelineItem>
      <TimelineItem
        key="005"
        dateText="05/2016 – 11/2016"
      >
        <h3>Web Designer, Terradiol</h3>
        <h4>Syracuse, NY</h4>
        <p>
        Terradiol is an emerging Bio-pharmaceutical company operating
        in Syracuse. They are promoting alternative cannabinoid healthcare
        in several states across the US. I worked closely with the company
        CEO to establish a <a href="/terradiol-company-website/">site design</a> that incorporated both the company’s
        aesthetic and the features necessary to communicate with partners.
        </p>
      </TimelineItem>
      <TimelineItem
        key="006"
        dateText="06/2015 – 08/2015"
      >
        <h3>Graphic Web Designer, MSLK</h3>
        <h4>New York, NY</h4>
        <p>
        <a href="http://mslk.com/">MSLK</a> is a small design firm in Long Island City. While
        interning there, one of the company heads, Sheri Koetting,
        had become a more active speaker around New York, and requested
        that I design and execute a <a href="http://sherikoetting.com/">promotional site</a> that would
        exhibit her brand as well as easily allow her to communicate
        schedule times.
        </p>
      </TimelineItem>
      <TimelineItem
        key="007"
        dateText="05/2015 - 06/2015"
      >
        <h3>Brand Designer, Graj + Gustavsen</h3>
        <h4>New York, NY</h4>
        <p>
        Worked at G+G to help with brand positioning services.
        Researched client backgrounds and participated in brainstorming
        meetings. Also helped curate photos for the A.T. Cross Company’s
        <a href="https://web.archive.org/web/20160409231555/http://www.cross.com:80/X"> X campaign</a>.
        </p>
      </TimelineItem>
      <TimelineItem
        key="008"
        dateText="01/2015 – 04/2015"
      >
        <h3>Interface Designer, DINEIN.CO.UK</h3>
        <h4>London, UK</h4>
        <p>
        DINEIN was a food delivery service operating in London.
        They provided drivers to restaurants who wanted to deliver
        around the city, along with a web interface used by customers
        to place orders. I worked directly the company CEO and
        assisted with a <a href="/dinein-ui-revamp/">website redesign</a>, which streamlined user
        interactions with the site and improved responsiveness.
        Also helped finalize user interface for ordering food
        through Pizza Express’ delivery service.
        </p>
      </TimelineItem>
      <TimelineItem
        key="009"
        dateText="06/2012 – 08/2012"
      >
        <h3>Work Study, Arrowmont School of Arts and Crafts</h3>
        <h4>Gatlinburg, TN</h4>
        <p>
        Was selected to participate in work study program
        at the Arrowmont School of Arts and Crafts. Took
        four weeks of woodturning and bamboo classes in
        exchange for four weeks of kitchen and housekeeping
        work.
        </p>
      </TimelineItem>
    </Timeline>

  )
}

export default Resume
